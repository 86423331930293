export function useAds() {
  const ads = [
    {
      image: "/images/thailand.png",
      url: "https://vn.trip.com/sale/w/11216/vivutrungquoc.html?locale=vi-vn&curr=vnd&transparentBar=1&promo_referer=3959_11216_4&Allianceid=5002474&SID=90945751&trip_sub1=articles&trip_sub3=P501503",
    },
    {
      image: "/images/china.png",
      url: "https://vn.trip.com/sale/w/11104/sawadeethailand.html?locale=vi-vn&curr=vnd&transparentBar=1&promo_referer=3959_11104_2&Allianceid=5002474&SID=90945751&trip_sub1=articles&trip_sub3=P501496",
    },

    {
      image: "/images/hotel_image.png",
      url: "https://vn.trip.com/sale/w/5171/summersale2024.html?locale=vi-vn&curr=vnd&transparentBar=1&promo_referer=3959_5171_1&Allianceid=5002474&SID=90945751&trip_sub1=&trip_sub3=P493852",
    },
    {
      image: "/images/hotel_image_air.png",
      url: "https://vn.trip.com/sale/w/10845/sieusale-airasia.html?locale=vi-vn&curr=vnd&transparentBar=1&promo_referer=3959_10845_3&Allianceid=5002474&SID=90945751&trip_sub1=sidebar-attractions&trip_sub3=P493859",
    },
  ];
  const randAds = (c: number) => {
    const shuffledArray = ads.slice().sort(() => 0.5 - Math.random());
    return shuffledArray.slice(0, c);
  };
  return {
    ads,
    randAds,
  };
}
